import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Images, Svgs } from "../../../assets/images";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import useBreadCrumb from "../../../hooks/useBreadCrumb";
import { useForm } from "react-hook-form";
import { ErrorToaster, SuccessToaster } from "../../../components/Toaster";
import { ErrorHandler } from "../../../utils/ErrorHandler";
import AuthServices from "../../../api/AuthServices/auth.index";
import { useAuth } from "../../../context";
import Loader from "../../../components/Loader";
import Colors from "../../../assets/styles";

function App() {
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = window.location.origin


  const { setName } = useBreadCrumb();
  const navigate = useNavigate();
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { userLogin } = useAuth();
  let sleep = () => new Promise((r) => setTimeout(r, 2000));
  const login = async (formData) => {
    const obj = {
      email: formData.email,
      password: formData.password,
    };
    setLoading(true);
    try {
      const  data= await AuthServices.login(obj);
      await sleep();
      SuccessToaster(data?.data?.message);
    
      userLogin(data?.data);
      if(data?.data?.role == "admin"){
        navigate("/dashboard");
        setName("/dashboard");
      }else if(data?.data?.role == "employee"){
        navigate("/home");
        setName("/home");
      }
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "120px",
          backgroundColor: "#F1FEFD",
          height: { xs: "100vh", md: "auto" },
        }}
      >
        <Box>
          <img
            src={Images.logo}
            alt="Bella Staffing Solutions Logo"
            style={{ width: "150px" }}
          />
        </Box>
        <Typography
          variant="h4"
          sx={{ marginTop: "20px", color: "#333", fontWeight: "bold" }}
        >
          Welcome Back
        </Typography>
        <Typography variant="body2" sx={{ marginTop: "10px", color: "#666" }}>
          Login into your account
        </Typography>
        <Box
          component="form"
          sx={{
            marginTop: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onSubmit={handleSubmit(login)}
        >
          <TextField
            margin="normal"
            fullWidth
            sx={{
              // background: Colors.white,
              width: "74%",
              // border: "1px solid grey",
              // outline: "none",
              "& fieldset": {
                borderRadius: "0px",
                border: "1px solid grey",
              },
              // "& fieldset": {
              //   border: "none",
              //   borderRadius: "0px",
              // },
              // "&:hover": {
              //   borderRadius: "0px",
              // },
            }}
            placeholder="Email"
            type="email"
            {...register("email", {
              required: "Please enter your Email",
            })}
            error={errors?.email?.message}
            helperText={errors?.email?.message}
          />
          <TextField
            margin="normal"
            fullWidth
            placeholder="Password"
            sx={{
              width: "74%",
              // background: Colors.white,

              "& fieldset": {
                borderRadius: "0px",
                border: "1px solid grey",
              },
            }}
            type={showPassword ? "text" : "password"}
            {...register("password", {
              required: "Please enter your Password",
              validate: (value) =>
                value.trim() !== "" || "Please enter your Password",
            })}
            error={errors?.password?.message}
            helperText={errors?.password?.message}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
          />
          <Box
            sx={{
              width: "75%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "20px",
              gap: "5px",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
              }
              label="Remember me"
              labelPlacement="end"
              sx={{ fontSize: "12px", fontWeight: "500" }}
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "74%",
              marginTop: "35px",
              backgroundColor: "white",
              color: "#5c5c5c",
              "&:hover": { backgroundColor: "#f0f0f0" },
            }}
          >
            {loading ? <Loader width={"20px"} height={"20px"} color={Colors.primary}  /> : "Login"}
          </Button>
          {params.includes("admin") ? (
            <Box sx={{mt:1}}></Box>
          ):(
            <Box sx={{mt:1}}>Dont have an account <span onClick={()=>navigate("/sign-up")} style={{fontWeight:"bold",cursor:"pointer"}}>Sign up</span></Box>
           

          )}
          
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          width: "100%",
          height: "100vh",
          display: { xs: "none", md: "flex" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            backgroundImage: `url(${Images.backgroundMain})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="bgImage"
            src={Images.backLayer}
            alt="Illustration"
            style={{ marginTop: "10px", width: "700px" }}
          />

          <Box
            sx={{
              background: "rgba(241, 254, 253, 0.13)",
              padding: "24px 11px",
              width: "80%",
              margin: " 0 auto",
              // padding:"10px 10px",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                pl: 2,
                ml:2,
                gap: "10px",
                background: "rgba(245, 117, 27, 1)",
                color: Colors.black,
                py: 1,
                fontSize: "13px",
                width: "200px",
                fontWeight: "bold  ",
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: Svgs["thumbs"] }}></span>{" "}
              Top Notch resources
            </Box>
            <Box
              sx={{
                mt: 1,
                color: Colors.white,
                textAlign:"justify",
                fontSize: "20px",
                borderRadius: "8px",
                mb: 1,
                px:2
              }}
            >
              Today, we create innovative solutions to the challenges that
              consumers face in both their everyday lives and events.
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default App;
