export const JobRoutes = {
    createJob: "/jobs/create",
    getJobs :"/jobs/",
    getAllJobs:"/jobs/getAllJobs",
    getJobDetails:"/jobs/details",
    updatejob:"/jobs/update",
    assignJob:"/jobs/assign",
    approveJob:"/jobs/approve",
    applyOnJob:"/jobs/applyOnJob",
    getAppliedJobs:"/jobs/getAppliedJobs",
    getAssignedJobs:"/jobs/getAssignedJobs"
  };
  