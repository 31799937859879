import { AuthRoutes } from "./auth.route";
import { post, get, put, patch } from "../index";


const AuthServices = {
  login: async (obj) => {
    let result = post(AuthRoutes.login ,obj );
    return result;
  },
  register: async (obj) => {
    let result = post(AuthRoutes.register ,obj );
    return result;
  },
  checkPhone: async (obj) => {
    let result = post(AuthRoutes.checkPhone ,obj );
    return result;
  },
  checkEmail: async (obj) => {
    let result = post(AuthRoutes.checkEmail ,obj );
    return result;
  },
  changePassword: async (obj) => {
    let result = patch(AuthRoutes.changePassword ,obj );
    return result;
  },

};

export default AuthServices;
