export const EmployeeRoutes = {
  createEmployee: "/employees/create",
  getEmployee: "/employees",
  getApplicantEmployee: "/employees/getJobApplicantEmployees",
  getEmployeeDetails: "/employees/details",
  deleteEmployee:"/employees/delete",
  UpdateEmployee:"/employees/update",
  SendMessage:"/employees/sendMessage",
  employeeRequest:"/employees/getEmployeeRequests",
  getEmployeeRequestDetail:"/employees/getEmployeeRequestDetails",
  updateRequest:"/employees/updateEmployeeRequest",
  getSingleEmployee:"/employees/getSingleEmployeeDetail"
};
