import {
  Box,
  Grid,
  Typography,
  InputLabel,
  Avatar,
  IconButton,
  TextField,
  MenuItem,
  Button,
  Autocomplete,
  FormControl,
  FormHelperText,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Colors from "../../../../assets/styles";
import { Controller, useForm } from "react-hook-form";
import PropertyServices from "../../../../api/PropertyServices/property.index";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorToaster, SuccessToaster } from "../../../../components/Toaster";
import { ErrorHandler } from "../../../../utils/ErrorHandler";
import CompanyServices from "../../../../api/CompanyServices/company.index";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AddressForm } from "../../../../components/AdressMap";
import SelectAddressDialog from "../../../../components/AdressMap/SelectAddressDialo";

export default function UpdateProperty() {
  const containerRef = useRef(null);
  const [managmentCompany, setManagmentCompany] = useState("");
  const [billingPreference, setBillingPreference] = useState("");
  const [area, setArea] = useState("");
  const [managmentCompanyData, setManagmentCompanyData] = useState([]);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [pages, setPages] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [allCities, setAllCities] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [addressFormDialog, setAddressFormDialog] = useState(false);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState({});
  const [addressLists, setAddressLists] = useState("");
  const [selectAddressDialog, setSelectAddressDialog] = useState(false);
  const [mapAddress, setMapAddress] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const itemPerPage = 30;
  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    if (term === "") {
      setCitiesData(allCities.slice(0, itemPerPage));
    } else {
      const filteredFonts = allCities.filter((font) =>
        font.toLowerCase().includes(term.toLowerCase())
      );
      setCitiesData(filteredFonts);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const obj = { country: "United States" };
      const response = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/cities",
        obj
      );
      setCitiesData(response?.data?.data?.slice(0, itemPerPage));
      setAllCities(response?.data?.data); // Adjust based on your API response structure
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const phoneNumberValidation = (value, country) => {
    const usPhonePattern = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{5})$/;
    const mxPhonePattern = /^(\+52)?\d{12}$/;

    if (country === "us") {
      return usPhonePattern.test(value) || "Please enter a valid US phone number";
    } else if (country === "mx") {
      return mxPhonePattern.test(value) || "Please enter a valid Mexican phone number";
    }
    return true;
  };
  const loadMoreFonts = useCallback(() => {
    console.log("sdaasdasd");
    const itemsPerPage = 30;
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const newCities = allCities.slice(startIndex, endIndex);
    const newFonts = newCities;
    setCitiesData((prevFonts) => [...prevFonts, ...newFonts]);
    // console.log(newFonts, "teste=");
    // setCitiesData(newFonts);
    setLoading(false);
  }, [page, loading, citiesData]);

  const handleScroll = () => {
    if (!containerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
      setPage((prevPage) => prevPage + 1);
      console.log(page);
    }
  };
  useEffect(() => {
    loadMoreFonts();
  }, [page]);
  const getCompanies = async (
    idParam = "",
    searchParam = "",
    dateFromParam = "",
    dateToParam = "",
    pageParam = 1,
    limitParam = 10
  ) => {
    try {
      const { data } = await CompanyServices.getCompany(
        idParam,
        searchParam,
        dateFromParam ? dateFromParam : "",
        dateToParam ? dateToParam : "",
        pageParam,
        limitParam
      );
      console.log(data.list);
      setManagmentCompanyData(data.list);
    } catch (error) {
      ErrorHandler(error);
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanies(id, search, dateFrom, dateTo, "1", "999");
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(state);
  useEffect(() => {
    if (state) {
      setValue("propertName", state.name);
      setValue("location", state.map_address);
      setManagmentCompany(state.company_id);
      setValue("zipCode", state.zip_code);
      setSearchTerm(state.area);
      setValue("area", state.area);
      setValue("phoneNumber", state.phone);
      setValue("contactPerson", state.cp_name);
      setValue("contactPersonCellPhone", state.cp_phone);
      setBillingPreference(state.billing_preference);
      setValue("notes", state.notes);
      setValue("fax", state?.fax);
    }
  }, [state, setValue]);
  const UpdateProperty = async (data) => {
    const obj = {
      _id: state._id,
      name: data.propertName,
      company_id: managmentCompany,
      address:"",
      map_address:mapAddress,
      latitude:lat,
      longitude:lng,
      zip_code: data.zipCode,
      area: searchTerm,
      phone: data.phoneNumber,
      cp_name: data.contactPerson,
      cp_phone: data.contactPersonCellPhone,
      billing_preference: billingPreference,
      notes: data.notes,
      fax:data?.fax
    };
    try {
      const data = await PropertyServices.updateProperty(obj);
      console.log(data);
      SuccessToaster(data?.message);
      navigate("/properties");
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error);
      console.log(error?.message);
    }
    console.log(obj);
  };
   const saveAddress = async (data) => {
    
    console.log(data)
    setValue("location" ,data.address);
    setValue("company_lat" ,data.latitude)
    setValue("company_lng" ,data.longitude)

    setMapAddress(data.address)
    setLat(data.latitude)
    setLng(data.longitude)
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "10px",
          alignItems: "center",
          mt: 9.5,
          pl: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "26px",
            color: "rgb(148 149 153)",
            fontWeight: "600",
          }}
        >
          Properties /
        </Typography>
        <Typography
          sx={{ fontSize: "22px", color: Colors.primary, fontWeight: "600" }}
        >
          Edit Property
        </Typography>
      </Box>
      <Box
        component={"form"}
        sx={{
          marginTop: "14px",
          marginLeft: "16px",
          marginRight: "16px",
          padding: "20px",
          backgroundColor: Colors.backgroundColor,
          boxShadow: "0xp 0px 100px 0xp rgba(0,0,0,0.1)",
          borderRadius: "8px",
        }}
        onSubmit={handleSubmit(UpdateProperty)}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          Property Details
        </Typography>
        <Box sx={{ mt: 1.5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                Property Name
              </InputLabel>
              <TextField
                fullWidth
                {...register("propertName", {
                  required: "Property Name is required",
                  validate: (value) =>
                    value.trim() !== "" || "Property Name is required",
                })}
                error={errors.propertName && true}
                helperText={errors?.propertName?.message}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                Management Company
              </InputLabel>
              <Autocomplete
                fullWidth
                options={managmentCompanyData}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.managmentCompany && true}
                    helperText={errors?.managmentCompany?.message}
                    {...register("managmentCompany", {
                      required:
                        managmentCompany === ""
                          ? "Management Company is required"
                          : false,
                    })}
                  />
                )}
                value={
                  managmentCompanyData.find(
                    (company) => company._id === managmentCompany
                  ) || null
                }
                onChange={(e, newValue) =>
                  setManagmentCompany(newValue ? newValue._id : "")
                }
              />
            </Grid>
            {/* <Grid item xs={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                Address
              </InputLabel>
              <TextField
                fullWidth
                {...register("address", {
                  required: "Adrress is required",
                  validate: (value) =>
                    value.trim() !== "" || "Adrress is required",
                })}
                error={!!errors.address}
                helperText={errors?.address?.message}
              />
            </Grid> */}
            <Grid item xs={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                Zip Code
              </InputLabel>
              <TextField
                fullWidth
                {...register("zipCode", {
                  required: "Zip Code is required",
                  validate: (value) =>
                    value.trim() !== "" || "Zip Code is required",
                })}
                error={!!errors.zipCode}
                helperText={errors?.zipCode?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: "black" }}>
                Area Town Preferred
              </InputLabel>
              <div>
                <div>
                  <div className="dropdown-container">
                    <input
                      type="text"
                      id="dropdown-font"
                      className="dropdown-toggle"
                      placeholder="Select Area"
                      style={{ fontFamily: searchTerm }}
                      value={searchTerm}
                      onClick={toggleDropdown}
                      onChange={handleSearch}
                    />
                    {isDropdownOpen && (
                      <div className="dropdown-content">
                        <div
                          ref={containerRef}
                          onScroll={handleScroll}
                          className="dropdown-list"
                        >
                          {citiesData.map((font) => {
                            return (
                              <div
                                key={font.family}
                                style={{
                                  fontFamily: font.family,
                                  cursor: "pointer",
                                  padding: "10px",
                                }}
                                onClick={(e) => {
                                  setSearchTerm(font);
                                  setIsDropdownOpen(false);
                                }}
                              >
                                {font}
                              </div>
                            );
                          })}
                          {loading && <div>Loading more Areas...</div>}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
            <InputLabel sx={{ fontWeight: "bold", color: "black" }}>
               Select Address
              </InputLabel>
              <TextField
                fullWidth
                value={getValues("location") || ""}
                defaultValue={selectedDeliveryAddress?.address}
                inputProps={{ readOnly: true }}
               
                onClick={() => {
                  setSelectAddressDialog(false);
                  setAddressFormDialog(true);
                }}
                // onClick={() => setSelectAddressDialog(true)}
                // placeholder="Enter Address"
                variant="outlined"
                {...register("location", {
                  required: "Location is required",
                })}
                helperText={errors.location?.message}
                error={!!errors.location}
              />
              <SelectAddressDialog
                open={selectAddressDialog}
                onClose={(data) => {
                  setSelectAddressDialog(false);
                  setSelectedDeliveryAddress(data);
                  setAddressFormDialog(false);
                }}
                addressLists={addressLists || []}
                addNewAddress={() => {
                  setSelectAddressDialog(false);
                  setAddressFormDialog(true);
                }}
                selectedAddress={selectedDeliveryAddress}
              />
              <AddressForm
                open={addressFormDialog}
                onClose={() => setAddressFormDialog(false)}
                save={(data) => saveAddress(data)}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                Phone Number
              </InputLabel>
              <FormControl
                        sx={{
                          ".form-control": {
                            height: "56px !important",
                            background: "transparent !important",
                          },
                        }}
                        error={!!errors.phoneNumber}
                        fullWidth
                      >
                        <Controller
                          name="phoneNumber"
                          control={control}
                          rules={{
                            required: "Phone number is required",
                            validate: (value, formValues) => {
                              // Get the selected country code from the PhoneInput value
                              const selectedCountry = value.slice(0, 2) === "52" ? "mx" : "us";
                              return phoneNumberValidation(value, selectedCountry);
                            },
                          }}
                          render={({ field }) => (
                            <PhoneInput
                              country={"us"}
                              onlyCountries={["us", "mx"]}
                              value={field.value}
                              countryCodeEditable={false}

                              onChange={field.onChange}
                              inputStyle={{ width: "100%" }}
                            />
                          )}
                        />
                        <FormHelperText>
                          {errors.phoneNumber ? errors?.phoneNumber?.message : ""}
                        </FormHelperText>
                      </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                Contact Person
              </InputLabel>
              <TextField
                fullWidth
                {...register("contactPerson", {
                  required: "Contact Person is required",
                  validate: (value) =>
                    value.trim() !== "" || "Contact Person is required",
                })}
                error={!!errors.contactPerson}
                helperText={errors?.contactPerson?.message}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                Contact Person Cell Phone
              </InputLabel>
              <FormControl
                        sx={{
                          ".form-control": {
                            height: "56px !important",
                            background: "transparent !important",
                          },
                        }}
                        error={!!errors.contactPersonCellPhone}
                        fullWidth
                      >
                        <Controller
                          name="contactPersonCellPhone"
                          control={control}
                          rules={{
                            required: "Contact Person Phone number is required",
                            validate: (value, formValues) => {
                              // Get the selected country code from the PhoneInput value
                              const selectedCountry = value.slice(0, 2) === "52" ? "mx" : "us";
                              return phoneNumberValidation(value, selectedCountry);
                            },
                          }}
                          render={({ field }) => (
                            <PhoneInput
                              country={"us"}
                              onlyCountries={["us", "mx"]}
                              value={field.value}
                              onChange={field.onChange}
                              countryCodeEditable={false}

                              inputStyle={{ width: "100%" }}
                            />
                          )}
                        />
                        <FormHelperText>
                          {errors.contactPersonCellPhone ? errors?.contactPersonCellPhone?.message : ""}
                        </FormHelperText>
                      </FormControl>
            </Grid>
            <Grid item xs={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                Fax
              </InputLabel>
              <TextField
                fullWidth
                {...register("fax", {
                  required: "Faxis required",
                  validate: (value) => value.trim() !== "" || "Fax is required",
                })}
                error={!!errors.fax}
                helperText={errors?.fax?.message}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                Billing Preference
              </InputLabel>
              <TextField
                fullWidth
                select
                {...register("billingPreference", {
                  required:
                    billingPreference == ""
                      ? "Billing Preference is required"
                      : false,
                })}
                error={errors.billingPreference && true}
                helperText={errors?.billingPreference?.message}
                value={billingPreference}
                onChange={(e) => setBillingPreference(e.target.value)}
              >
                <MenuItem value="directDeposit">Direct Deposit</MenuItem>
                <MenuItem value="mail">Mail</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={5}>
              <InputLabel sx={{ fontWeight: "bold", color: Colors.black }}>
                Additional Notes
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                // rows={2}
                {...register("notes")}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mx: 1 }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
