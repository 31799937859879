import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Grid,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../context";
import Colors from "../../assets/styles";
import DocumentServices from "../../api/DocumentServices/index";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { jwtDecode } from "jwt-decode";
import SignaturePad from "react-signature-canvas";
import { ErrorToaster, SuccessToaster } from "../../components/Toaster";
import Loader from "../../components/Loader";
import SimpleDialog from "../../components/Dialog";

export default function ViewDocument() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("");
  const [openSignDialog, setOpenSignDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [documentDetail, setDocumentDetail] = useState("");
  const [status, setStatus] = useState("");

  const [documentName, setDocumentName] = useState("");
  const theme = useTheme();
  const { state } = useLocation();
  console.log(state);

  const { user } = useAuth();
  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const decodedToken = user ? jwtDecode(user.token) : {};
  const userIdFromToken = decodedToken.employee_id;
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const getDocuments = async () => {
    setLoading(true);
    try {
      const { data } = await DocumentServices.getDoc(
        state ? state : userIdFromToken,
        id
      );
      setDocuments(data?.list || []);
    } catch (error) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocuments();
  }, []);

  const handleApprove = async () => {
    console.log(documentDetail);
    const obj = {
      id: documentDetail?._id,
      type: documentDetail?.type,
      status: status,
    };
    setLoading(true);
    try {
      const { data, message } = await DocumentServices.approveDoc(obj);
      SuccessToaster(message);
      setOpenDialog(false);
      getDocuments();
      setLoading(false);
      setStatus("");
    } catch (error) {
      ErrorHandler(error);
      ErrorToaster(error);
      setStatus("");
    } finally {
      setLoading(false);
      setStatus("");
    }
  };

  useEffect(() => {
    getDocuments();
  }, []);

  const sigPadRef = useRef({});

  const handleViewClick = (documentUrl) => {
    console.log(documentUrl);
    setSelectedDocumentUrl(documentUrl);
    setOpenModal(true);
  };

  const handleSignClick = (job) => {
    setOpenSignDialog(true);
  };

  const clearSignature = () => {
    sigPadRef.current.clear();
  };

  //   const submitSignature = async () => {
  //     const base64Signature = sigPadRef.current.toDataURL();
  //     const obj = {
  //       job_id: id,
  //       employee_id: userIdFromToken,
  //       type: documentName?.type,
  //       sign: base64Signature,
  //     };

  //     console.log("Signature Base64:", base64Signature);
  //     try {
  //       const { data, message } = await DocumentServices.SignDoc(obj);
  //       SuccessToaster(message);
  //       setOpenSignDialog(false);
  //       getDocuments();
  //     } catch (error) {
  //       ErrorHandler(error);
  //       ErrorToaster(error);
  //     } finally {
  //       setLoading(false);
  //       setOpenSignDialog(false);
  //     }
  //   };

  const submitSignature = async () => {
    // Check if the signature pad is empty
    if (sigPadRef.current.isEmpty()) {
      ErrorToaster("Please provide a signature before submitting.");
      return;
    }

    const base64Signature = sigPadRef.current.toDataURL();
    const obj = {
      job_id: id,
      employee_id: userIdFromToken,
      type: documentName?.type,
      sign: base64Signature,
    };
    setLoading1(true);

    console.log("Signature Base64:", base64Signature);
    try {
      const { data, message } = await DocumentServices.SignDoc(obj);
      SuccessToaster(message);
      setOpenSignDialog(false);
      getDocuments();
      setLoading1(false);
    } catch (error) {
      ErrorHandler(error);
      setLoading1(false);

      ErrorToaster(error);
    } finally {
      setLoading(false);
      setOpenSignDialog(false);
      setLoading1(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDocumentUrl("");
  };

  const handleCloseSignDialog = () => {
    setOpenSignDialog(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "10px",
          alignItems: "center",
          mt: 9.5,
          pl: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "26px",
            color: Colors.primary,
            fontWeight: "600",
          }}
        >
          View Documents
        </Typography>
      </Box>

      {/* Document Table */}
      {matches && user.role == "employee" ? (
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {loading ? (
              <Box sx={{ margin: "0 auto" }}>
                <Loader width="40px" height="40px" color={Colors.primary} />
              </Box>
            ) : documents.length === 0 ? (
              // Show "No data found" message if there's no data
              <Grid item xs={12}>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: Colors.gray, // Adjust the color as per your theme
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  No data found
                </Typography>
              </Grid>
            ) : (
              documents.map((document, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card
                    sx={{
                      background: Colors.dashboardBgColor,
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      borderRadius: "12px",
                    }}
                  >
                    <CardContent>
                      <Box sx={{ display: "flex", mb: 1 }}>
                        <Typography sx={{ fontWeight: "bold", width: "150px" }}>
                          SR No :
                        </Typography>
                        <Typography>{index + 1}</Typography>
                      </Box>
                      <Box sx={{ display: "flex", mb: 1 }}>
                        <Typography sx={{ fontWeight: "bold", width: "150px" }}>
                          Document Name
                        </Typography>
                        <Typography> {document.document_name}</Typography>
                      </Box>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ mx: 1 }}
                        onClick={() => {
                          handleViewClick(
                            document.signedDocument
                              ? document.signedDocument
                              : document.document_url
                          );
                          setDocumentName(document);
                        }}
                      >
                        View
                      </Button>
                      {document.signedDocument && user.role == "employee" ? (
                        <Button
                          sx={{
                            background: Colors.seaGreen,
                            mx: 1,
                            color: Colors.white,
                            ":hover": { background: Colors.seaGreen },
                          }}
                        >
                          Signed
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ mx: 1 }}
                          onClick={() => {
                            setDocumentName(document);
                            handleSignClick();
                          }}
                        >
                          Sign
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            mt: 2,
            backgroundColor: Colors.backgroundColor,
            borderRadius: "10px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                  Sr. No.
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                  Document Name
                </TableCell>
                {user.role === "admin" && (
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                    Status
                  </TableCell>
                )}
                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={user.role === "admin" ? 4 : 3}
                    sx={{ textAlign: "center" }}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : documents.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={user.role === "admin" ? 4 : 3}
                    sx={{ textAlign: "center" }}
                  >
                    No documents found.
                  </TableCell>
                </TableRow>
              ) : (
                documents.map((document, index) => (
                  <TableRow key={document._id}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {document.document_name}
                    </TableCell>
                    {user.role === "admin" && (
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography
                          sx={{
                            backgroundColor:
                              document.isVerify == null
                                ? Colors.yellow
                                : document.isVerify == true
                                ? Colors.seaGreen
                                : Colors.red,
                            borderRadius: "20px",
                            textAlign: "center",
                            px: 1,
                            py: 0.5,
                            color: Colors.white,
                            fontSize: "12px",
                            textTransform: "capitalize ",
                          }}
                          onClick={() => {
                            setDocumentDetail(document);
                            if(document.isVerify == null){

                              setOpenDialog(true);
                            }
                          }}
                        >
                          {document?.isVerify === false
                            ? "Reject"
                            : document?.isVerify === true
                            ? "Approved"
                            : "Pending"}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell sx={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: "20px",
                          textAlign: "center",
                          px: 2,
                          fontSize: "12px",
                          py: 0.5,
                        }}
                        onClick={() => {
                          handleViewClick(
                            document.signedDocument
                              ? document.signedDocument
                              : document.document_url
                          );
                          setDocumentName(document);
                        }}
                      >
                        View
                      </Button>
                      {document.signedDocument && user.role == "employee" ? (
                        <Button
                          sx={{
                            background: Colors.seaGreen,

                            borderRadius: "20px",
                            textAlign: "center",
                            px: 2,
                            fontSize: "12px",
                            py: 0.5,
                            mx: 1,
                            color: Colors.white,
                            ":hover": { background: Colors.seaGreen },
                          }}
                        >
                          Signed
                        </Button>
                      ) : user.role == "employee" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            mx: 1,
                            borderRadius: "20px",
                            textAlign: "center",
                            px: 2,
                            fontSize: "12px",
                            py: 0.5,
                          }}
                          onClick={() => {
                            setDocumentName(document);
                            handleSignClick();
                          }}
                        >
                          Sign
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Signature Dialog */}
      <Dialog
        open={openSignDialog}
        onClose={() => setOpenSignDialog(false)}
        fullWidth
        maxWidth="sm"
        sx={{ ".MuiPaper-elevation": { borderRadius: "13px !important" } }}
      >
        <DialogTitle sx={{ color: Colors.primary, textAlign: "center" }}>
          Signature on {documentName?.document_name}
        </DialogTitle>
        <DialogContent>
          <SignaturePad
            ref={sigPadRef}
            canvasProps={{
              //   width: "100%",
              height: 200,
              style: {
                border: "1px solid #000",
                width: "99%",
                borderRadius: "10px ",
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ mr: 2 }}>
          <Button
            disabled={loading1}
            onClick={clearSignature}
            variant="contained"
            color="primary"
          >
            Clear
          </Button>
          <Button
            disabled={loading1}
            onClick={submitSignature}
            variant="contained"
            color="primary"
          >
            {loading1 ? (
              <Loader width={"20px"} height={"20px"} color={Colors.white} />
            ) : (
              "Submit Signature"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* PDF View Modal */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: { height: "98vh" },
        }}
      >
        <DialogContent
          sx={{
            height: "100%",
            padding: 0,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {selectedDocumentUrl ? (
            <iframe
              src={baseUrl + selectedDocumentUrl}
              width="100%"
              height="100%"
              title="PDF Preview"
              style={{ border: "none", overflow: "hidden" }}
            />
          ) : (
            <Typography>Unable to load document.</Typography>
          )}
        </DialogContent>
      </Dialog>
      <SimpleDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setStatus("");
        }}
        border={`4px solid ${Colors.primary}`}
        title="Change Status"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
            mt: 5,
          }}
        >
          <Box
            sx={{
              width: "100%",
              mb: 3,
            }}
          >
            <InputLabel>Select Status</InputLabel>
            <FormControl fullWidth>
              <Select
                placeholder="Select Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={true}>Approved</MenuItem>
                <MenuItem value={false}>Rejected</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "16px",
            }}
          >
            <Box
              onClick={() => {
                setOpenDialog(false);
                setStatus("");
              }}
              sx={{
                background: Colors.black,
                color: Colors.primary,
                borderRadius: "10px",
                px: 5,
                py: 2,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              No, Cancel
            </Box>
            <Box
              sx={{
                color: Colors.black,
                background: Colors.primary,
                borderRadius: "10px",
                px: 5,
                py: 2,
                fontWeight: "bold",
                cursor: "pointer",
                cursor: loading ? "not-allowed" : "pointer",
                opacity: loading ? 0.6 : 1,
                pointerEvents: loading ? "none" : "auto",
              }}
              onClick={loading ? undefined : handleApprove}
            >
              {loading ? (
                <Loader width={"20px"} height={"20px"} color={Colors.black} />
              ) : (
                "Yes, Confirm"
              )}
            </Box>
          </Box>
        </Box>
      </SimpleDialog>
    </>
  );
}
