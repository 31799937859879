import Dashboard from "../../views/admin/dashboard";
import Login from "../../views/admin/Login/index";

import CreateEmployee from "../../views/admin/Employees/CreateEmployee/index"
import EmployeeList from "../../views/admin/Employees/EmployeeList/index"
import EmployeeDetails from "../../views/admin/Employees/EmployeeDetails/index"
import UpdateEmployee from "../../views/admin/Employees/UpdateEmployee/index";

import CreateCompany from "../../views/admin/Company/CreateCompany/index"
import CompanyList from "../../views/admin/Company/CompanyList/index"
import CompanyDetails from "../../views/admin/Company/CompanyDetail";
import UpdateCompany from "../../views/admin/Company/UpdateCompany/index";

import CreateProperty from "../../views/admin/Property/CreateProperty/index"
import PropertyList from "../../views/admin/Property/PropertyList/index"
import PropertyDetails from "../../views/admin/Property/PropertyDetails";
import UpdateProperty from "../../views/admin/Property/UpdateProperty";

import CreateJobRequest from "../../views/admin/Jobs/JobRequest/CreateJobRequest/index"
import JobRequestList from "../../views/admin/Jobs/JobRequest/JobRequestList/index"
import JobApplicant from "../../views/admin/Jobs/JobApplicant/index"
import UpdateJobRequest from "../../views/admin/Jobs/JobRequest/UpdateJobRequest/index"
import AssignedJob from "../../views/admin/Jobs/JobRequest/AssignedJob/index"
import JobsList from "../../views/admin/Jobs/JobsList/index"
import Create from "../../views/admin/CreateSkill/index"
import JoDetails from "../../views/admin/Jobs/JobRequest/JobDetail";
import AssignedEmployee from "../../views/admin/Jobs/JobRequest/AssignedJob/AssignedEmployee";

import CreateCode from "../../views/admin/CreateSkill/code"
import EmployeeRequest from "../../views/admin/Employees/EmployeeRequest";
import EmployeeRequestDetail from "../../views/admin/Employees/EmployeeRequestDetail";
import ApplicantList from "../../views/admin/Jobs/JobRequest/ApplicantList";
import JobsReport from "../../views/admin/Jobs/JobsReport";
import AccountSetting from "../../views/AccountSetting";
import ViewDocument from "../../views/ViewDocument";


const AdminRoutes = [
  {
    path: "/login",
    component: <Login />
  },
  {
    path: "/dashboard",
    component: <Dashboard />
  },
  {
    path: "/create/skill",
    component: <Create />
  },
  {
    path: "/employees",
    component: <EmployeeList />
  },
  {
    path: "/employees/create",
    component: <CreateEmployee />
  },
  {
    path: "/employees/update/:id",
    component: <UpdateEmployee />
  },
  {
    path: "/employees/details/:id",
    component: <EmployeeDetails />
  },
  {
    path: "/employees-request/details/:id",
    component: <EmployeeRequestDetail />
  },
  {
    path: "/companies",
    component: <CompanyList />
  },
  {
    path: "/companies/create",
    component: <CreateCompany />
  },
  {
    path: "/companies/update",
    component: <UpdateCompany />
  },
  {
    path: "/companies/details",
    component: <CompanyDetails />
  },
  {
    path: "/properties",
    component: <PropertyList />
  },
  {
    path: "/properties/create",
    component: <CreateProperty />
  },
  {
    path: "/properties/update",
    component: <UpdateProperty/>
  },
  {
    path: "/properties/details",
    component: <PropertyDetails/>
  },
  {
    path: "/jobrequest",
    component: <JobRequestList />
  },
  {
    path: "/jobrequest/create",
    component: <CreateJobRequest />
  },
  {
    path: "/jobrequest/update/:id",
    component: <UpdateJobRequest />
  },
  {
    path: "/jobrequest/details/:id",
    component: <JoDetails />
  },
  {
    path: "/jobrequest/assignto",
    component: <AssignedJob />
  },
  {
    path: "/jobrequest/assign-employee",
    component: <AssignedEmployee />
  },
  {
    path: "/jobs",
    component: <JobsList />
  },
  {
    path: "/create/code",
    component: <CreateCode />
  },
  {
    path: "/employee/request",
    component: <EmployeeRequest />
  },
  {
    path: "/job-applicant/applicant-list/:id",
    component: <ApplicantList />
  },
  {
    path: "/job/report",
    component: <JobsReport />
  },
  {
    path: "/account/setting",
    component: <AccountSetting />
  },
  {
    path: "/job-applicant/view-doc/:id",
    component: <ViewDocument />
  },
  {
    path: "/job-applicant",
    component: <JobApplicant />
  },
];

export default AdminRoutes;